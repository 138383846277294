import React from 'react'
import {Heading,SimpleGrid,Box,Text, HStack} from '@chakra-ui/react'
import {StaticImage} from 'gatsby-plugin-image'
import {motion} from 'framer-motion'
import {Link} from 'gatsby'
import MeinSeo from '../components/seo'


export default function Beitrag4(){

return(
  <motion.div
  initial={{opacity:0,scale:0.9,paddingBottom:"100px"}}
  animate={{opacity:1,scale:1}}
  transition={{duration:.7,ease:"easeInOut"}}
  >
<Box >
  <MeinSeo title="Fotos zum Wernerkult" description='Fotodokumentation zum Wernerkult'/>
<HStack mb="15" justifyContent="space-between">



<Box>
<StaticImage
  src="../images/werner/26.jpeg"
  alt="Werner Abbildung"
 
  layout='constrained'
  height={620}
  width={500}
/>
</Box>
<Box>
<StaticImage
  src="../images/werner/10.jpg"
  alt="Werner Abbildung"
  layout='constrained'
  height={620}
  width={500}
/>

</Box>
</HStack>
<Box display="flex" justifyContent="flex-end"><Link to="/wernerdokumente"> <Text _hover={{color:"blue"}} fontSize="sm">&rarr; Fotogalerie zum Wernerkult</Text></Link></Box>


<SimpleGrid columns={[1,1,2]} gap="3">
 <Box> 
 <Heading mt="3" variant="Titel">
    Der Wernerkult 
</Heading>
<Text variant='solid' color="red.500">
Vernissage: 18. Juni um 15 Uhr im Kulturhaus Oberwesel
mit einem Vortrag von Prof. Dr. Alexander Lohner 
</Text>
<Text variant='solid'>
Während in Bacharach die Ausstellung zum Schicksal der Juden mit einer Veranschaulichung des religiösen Lebens gekoppelt ist, wird sie in Oberwesel mit einer Dokumentation zum christlichen Antijudaismus kombiniert. Der Märtyrerkult um den „Heiligen“ Werner von Oberwesel reicht bis ins 13. Jahrhundert zurück und diente nicht zuletzt der Mobilisierung von Pogromen. Die Ritualmordlüge besagt, dass ein christlicher Junge 1287 von seinen jüdischen Brotherren in Oberwesel ermordet, „geschächtet“ und bei Bacharach verscharrt worden sei. 
</Text>
<Text variant='solid'>
In den folgenden Jahrhunderten wurde die Legende durch Wunderberichte, Wallfahrtskirchen, Prozessionen, Reliquienverehrung und künstlerische Darstellungen zu einem Lokalkult verdichtet, der bis ins 20. Jahrhundert hinein lebendig blieb.
</Text>
<Text variant="solid">
Der Wernerkult am Mittelrhein ist heute abgeschafft dank des jahrelangen geduldigen Einsatzes von Menschen, die sich im christlich-jüdischen Dialog vor Ort engagiert haben.
</Text>

<Text variant='solid'>
  In 60 teils hochwertigen Exponaten wird der in Oberwesel bis 1971 bestehende antijüdische Kult um das angebliche Ritualmordopfer Werner von Oberwesel kontextualisiert. Dazu zählen Altarbilder, Statuen, Reliquiare, Bücher und Dokumente. Das wertvollste Exponat ist ein liturgischer Kelch von 1753 mit einer Ritualmorddarstellung.
  </Text>
  <Text variant="solid">
    <span style={{fontWeight:500}}>WERNER von Oberwesel</span>, ein unbekannter junger Tagelöhner, wurde im Frühjahr 1287 als christlicher Märtyrer in Bacharach bestattet.
    Seine übel zugerichtete Leiche war außerhalb des Ortes aufgefunden worden.</Text>
   
    <Text variant="solid">
    Der Pfarrer Heinrich von Crumbach nutzte dies zur Schaffung eines Heiligen, was dem Pfalzgrafen Ludwig II. gelegen kam. Die Juden im benachbarten Oberwesel (in Bacharach gab es keine mehr) wurden beschuldigt,
    den Jungen umgebracht zu haben. Ein  aufgehetzter Mob massakrierte sie, um den vermeintlichen Ritualmord zu rächen. 
    </Text>
    <Text variant='solid'>
    
    Die Pogrome weiteten sich auf über zwanzig Orte an Rhein und Mosel sowie auf dem Hunsrück aus. Über dem Sarkophag des »Guten Werner« ließ der Pfalzgraf eine aufwändige Grabkapelle erbauen. Darin fand 1428 ein großer      Untersuchungsprozess zur Sicherung des durch Bullen des Papstes Martin V.
 gefährdeten Kultes statt. Den Prozess hatte bei einem Besuch der päpstliche
  Legat Kardinal Giordano Orsini veranlasst. Pfalzgraf Ludwig III. und Pastor
  Winand von Steeg ließen 211 Zeugen befragen. Die Heiligsprechung blieb allerdings aus.
        </Text>
        <Text variant="solid">
        Dennoch zog der Kult in Bacharach bis zu seiner Unterbindung in der Reformation zahlreiche Pilger an.
        Im katholischen Oberwesel hielt er sich und blühte um 1727 erneut auf. 1742
        wurde »Sankt Werner« zum Trierer Bistumsheiligen erklärt.
        </Text>
        <Text variant="solid">
        1548 gelangte eine
        Fingerreliquie nach Besançon. Das bildete den Auftakt für die Verehrung Werners
        als Schutzpatron der Winzer, hier »St. Vernier« bzw. »St. Verny« genannt, in der Franche-Comté,
        der Auvergne und in Burgund, sie dauert bis ins 21. Jahrhundert an.   
1621 hoben unter der spanischen Besatzung jesuitische Feldgeistliche in Bacharach die Gebeine und nahmen sie mit in die Spanischen
            Niederlande, in Lille verliert sich ihre Spur. In zahllosen Heiligenlegenden wurde die
            Wernergeschichte jahrhundertelang variantenreich tradiert und nährte den christlichen
            Antijudaismus.
            </Text>
            <Text variant="solid">
            Die Ritualmordlüge war längst mit der Verleumdung der Hostienschändung kombiniert, was die NS-Propaganda, u. a. im »Stürmer«, mehrfach aufgriff.
            </Text>
            <Text variant='solid'>
            Im Kontext des II. Vatikanischen Konzils erreichte Erwin Iserloh 1963 die Tilgung des Kultes. Ferdinand Pauly unternahm 1964 den Versuch, den Kult zu retten,
            indem er den Jungen zum Opfer eines Sexualmordes erklärte.
            </Text>
            <Text variant="solid" >
            Die letzte Oberweseler Wernerprozession fand 1971 statt.
            1997 wurde die Ruine der Bacharacher Wernerkapelle als Mahnmal gestaltet,
            2008 das Wernerpatrozinium der Oberweseler Hospitalkapelle beendet.
            Am Mittelrhein finden sich, ebenso wie im Südosten Frankreichs, zahlreiche Darstellungen
            des angeblichen Märtyrers in Kirchen und Kapellen.

        </Text>
      
        <Link to="/wernerdokumente"><Text variant='solid' color="teal.500"> &rarr; Fotos / Dokumente zum Wernerkult. </Text> </Link>

  <Text variant='solid'>
Für die Vernissage in Oberwesel hält   <span style={{fontWeight:"600"}}>Hon.-Prof. Dr. Dr. Alexander Lohner</span> einen <span style={{textDecoration:'underline'}}>Vortrag über den christlichen Antijudaismus</span>.
</Text>

</Box>

<Box textAlign="right" mt="10" p="2">
  <Box my="8">
  <StaticImage src="../images/solo/walterkarbach.jpg" alt="Dr. Walter Karbach" height={500} /> 
  <Text variant='Bild'>Dr. Walter Karbach</Text>
  </Box>
  <StaticImage src="../images/solo/karbachcover.jpg" height={500} alt="Buch von Karbach"/> 
  <Text variant='Bild'>Standardwerk zum lokalen Wernerkult von Dr. Walter Karbach</Text>
   
  <Box my="6" >

   <Box py="3" pr="1">
  
   <a rel="noreferrer nopener" target="_blank" href="https://open.spotify.com/show/5cgsw63TlArgSZtSuEyHhK">
    
     <Text textAlign={["center","right"]} fontSize="sm" fontWeight="600" mr="1" color="red.400">&rarr; PODCAST: Menschen vom Mittelrhein<br/>Interview mit Peter Keber zur Wernerkapelle</Text></a>
     <StaticImage src="../images/peter-keber.jpeg" alt ="Peter Keber" width={340}  />
    
   </Box>
   
  </Box>
 
 
  <Box m="1">
 
 
  <Box m="0">
  <StaticImage src="../images/werner/20.jpg" alt="werner"  height={500} width={320}/> 
  <Text variant='Bild'></Text>
  </Box>
  <Box m="0">
  <StaticImage src="../images/werner/16.jpg" alt="wernerbild"  width={320}/> 
  <Text variant='Bild'></Text>
  </Box>
  </Box>

  </Box>
  

</SimpleGrid>
{/* <SimpleGrid mt="0" gap={8} columns={[1,1,2]}>
    <Box>
  
        
                <Box>
                  <Link to="/wernerdokumente"><Text variant='solid' color="teal.500"> &rarr; Fotos &amp; Dokumente zum Wernerkult. <br/>(Fotos von Dr. Walter Karbach)</Text> </Link>
                  <Text fontWeight="500">Texte von Dr. Walter Karbach</Text>
                </Box>

                </Box>
    </SimpleGrid> */}

</Box>
</motion.div>
    )
}